<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <Progress ref="progress" />

  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import GlobalStore from "@/store/AuthenticationStore";
import {HttpClient} from "@/plugins/http-client";
import Progress from "@/components/Progress.vue";
import {ProgressBar} from "@/components/ProgressBar";

export default Vue.extend({
  name: 'App',
  components: {Progress},
  data: () => ({
    snackbar: true,
    text: '',
  }),
  created(){
    console.log("App:: created", this.$route.query)
    const token = (this.$route.query?.token || '') as any as string;
    GlobalStore.setAccessToken(token);
    const httpClient = new HttpClient(
        process.env.VUE_APP_BASE_API_URL|| '',
        {Authorization: `token ${GlobalStore.token}`}

    );
    console.log("App:: httpClient", httpClient, process.env.VUE_APP_BASE_API_URL)
  },
  async mounted(){
    try {
      ProgressBar.init(this.$refs.progress as any as Progress);
      console.log("App:: mounted")
    } catch (error) {
      // ("App:: error", error)
    }
  },
  methods: {
  }
});
</script>

<style lang="scss">
#app{
  font-family: Pretendard, Roboto;
  color: #505050;
  font-size: 14px;
  background: none;
  min-height: 0;
  .v-application--wrap{
    min-height: 0;
    height: 0;
    flex: none;

  }
   .v-main{
    min-height: 0;
    height: 0;
     flex: none;
   }
  .v-main__wrap{
    min-height: 0;
    height: 0;
  }
}
</style>
