/* eslint-disable */
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import {HttpClient} from "@/plugins/http-client";

@Module({ store: store, namespaced: true, name: 'AuthenticationStore', dynamic: true })
class AuthenticationStore extends VuexModule {
    private accessToken = '';

    get token(): string {
        return this.accessToken;
    }

    @Mutation
    setAccessToken(token: string): void {
        this.accessToken = token;
    }
}

export default getModule(AuthenticationStore);
