import axios, { AxiosInstance, AxiosResponse } from 'axios';

export class HttpClient {
    static instance: AxiosInstance;

     constructor(baseURL: string, headers= {}) {
         HttpClient.instance = axios.create({
            baseURL,
            headers:  headers
        });
        this._initializeResponseInterceptor();
    }

    private _initializeResponseInterceptor = () => {
        HttpClient.instance.interceptors.response.use(
            this._handleResponse,
            this._handleError,
        );
    };

    private _handleResponse = ({ data }: AxiosResponse) => data;

    protected _handleError = (error: any) => Promise.reject(error);
}