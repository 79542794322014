<template>
  <v-btn class="custom-button" :disabled="disabled" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.custom-button{
  border: 1px solid #cdcdcd;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px 0px;
  border-radius: 10px;
  text-transform: none;
}
</style>
