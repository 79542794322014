export enum ActionType {
    Increase = 'increase',
    Decrease = 'decrease',
    DoubleIncrease = 'double_increase',
    DoubleDecrease = 'double_decrease',
    TurnOff = 'turnoff',
    TurnOffNextTime = 'turnoff_next_time',
    Set = 'set'
}

export class Action{
    constructor(
        public action_type: ActionType,
        public target_field: string,
        public target_value: string,
        public turn_on_time: string|null
    ) {}

    static empty(): Action {
        return new Action(
            ActionType.Increase,
            '',
            '0',
            null
        );
    }
}
