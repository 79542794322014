<template>
  <div>
    <template v-if="isTest">
      <v-btn
          @click="toggleWithId()"
      >
        toggle
      </v-btn>
      <text-input
          v-model="testId"
          placeholder="Id"
          type="number"
      >

      </text-input>
    </template>

    <v-dialog
        v-model="visible"
        width="100%"
        height="100%"
        scrollable
        :fullscreen="$vuetify.breakpoint.mobile"
        :transition="false"
        :max-width="768"
        target="parent"
        :persistent="true"

    >
      <template #default>
        <v-card color="white">
          <v-card-title>
            <v-row justify="space-between" dense style="position: relative">
              <div
                  :style="{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent:'center',
                flex: 1,
              }"
              >
                {{ title }}
              </div>

              <v-btn
                  style="position: absolute;top: -4px; right: -12px"
                  color="white"
                  elevation="0"
                  @click.stop="closeDialog"
                  small
                  fab
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-card-text class="pt-2 px-0" style="position: relative">
            <div
                v-if="loading"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: none;
                  z-index: 1;
                "
            >
<!--              <v-progress-circular-->
<!--                  color="#e33223"-->
<!--                  indeterminate-->
<!--              ></v-progress-circular>-->
            </div>
            <button v-if="isTest" @click="()=>isPreviewMode=!isPreviewMode">Toggle</button>
            <v-container v-if="isPreviewMode" :class="{'preview-view': isPreviewMode}" class="rule-form-container px-5" style="background: white" fluid>
              <v-form ref="form">
                <v-row dense>
                  <v-col cols="6">
                    <div class="custom-form-control">
                      <span class="custom-form-control--title">
                        규칙명
                      </span>
                      <span class="preview-data font-weight-bold">{{ rule.name }}</span>
                    </div>
                  </v-col>

                </v-row>
<!--                <v-row dense>-->
<!--                  <v-col cols="12">-->
<!--                    <v-checkbox color="#e33223" class="custom-checkbox" readonly v-model="activated" label="Activated"></v-checkbox>-->
<!--                  </v-col>-->
<!--                </v-row>-->
                <v-row dense>
                  <v-col cols="3">
                    <div class="custom-form-control">
                      <span class="custom-form-control--title">
                        설정 항목
                      </span>
                      <span class="preview-data font-weight-bold">
                        {{ruleTypes.find(item => item.value === rule.data_type)?.text || ''}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <div class="custom-form-control">
                    <span class="custom-form-control--title">
                      규칙 종류
                    </span>
                      <span class="font-weight-bold preview-data">
                        {{modes.find(item => item.value === rule.rule_type)?.text || '' }}
                      </span>
                    </div>

                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="4">
                    <div class="custom-form-control">
                <span class="custom-form-control--title">
                  우선순위
                </span>
                          <span class="preview-data font-weight-bold">
                            {{rule.priority}}
                          </span>
                    </div>
                  </v-col>

                </v-row>
                <template v-if="rule.rule_type === RuleType.Automation">
                  <v-row align="center" dense>
                    <v-col cols="5" style="max-width: 270px">
                      <div class="custom-form-control">
                        <span class="custom-form-control--title">시작 시간: </span>
                        <span class="preview-data">{{getDisplayTime(rule.turn_on_time)}}</span>

                      </div>
                    </v-col>
                    <v-col cols="5" style="max-width: 270px">
                      <div class="custom-form-control">
                        <span class="custom-form-control--title">종료 시간: </span>
                        <span class="preview-data">{{getDisplayTime(rule.turn_off_time)}}</span>
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <template v-if="rule.rule_type === RuleType.Scheduler">
                  <v-row dense align="center">
                    <v-col cols="4">
                      <div class="custom-form-control">
                        <span class="custom-form-control--title">실행시간</span>
                        <span class="preview-data">{{getDisplayTime(schedulerRuntime)}}</span>
                      </div>

                    </v-col>
                  </v-row>
                </template>
                <div
                    v-for="(set,setIndex) in rule.condition_action_sets"
                    :key="'set-'+setIndex"
                    style="position: relative"
                    class="set-condition-container"
                >
                  <div  class="py-3">
                    <v-divider></v-divider>

                  </div>
                  <v-row v-if="set.conditions.length > 0" style="margin-bottom: 0" dense class="pt-2 pb-1">
                    <v-col>
                      조건:
                    </v-col>
                    </v-row>
                  <v-row align="center" dense v-for="(con, conIndex) in set.conditions" :key="'con-'+conIndex">
                      <v-col cols="12">
                        <span class="preview-data font-weight-bold mr-3">
                        {{conditionFields.find(item =>item.value === con.field)?.text || '' }}
                      </span>

                        <span class="preview-data font-weight-bold  mr-3">
                        {{conditionOperators.find(item =>item.value === con.operator)?.text || '' }}
                      </span>

                        <span class="preview-data font-weight-bold">{{con.value}}</span>
                      </v-col>


                  </v-row>
                  <v-row style="margin-bottom: 0" dense>
                    <v-col>
                      액션(실행):
                    </v-col>
                  </v-row>
                  <v-row align="center" dense v-for="(action, actionIndex) in set.actions" :key="'action-'+actionIndex">
                    <v-col cols="12">
                      <span class="preview-data font-weight-bold mr-3">{{actionTypes.find(item=> item.value === action.action_type)?.text || ''}}</span>
                      <template v-if="action.action_type===ActionType.TurnOff">
                        <span class="mr-2">다시 켜지는 시간 :</span>

                        <span class="preview-data mr-3">{{getDisplayTime(action.turn_on_time)}}</span>

                      </template>
                      <template v-else>
                        <span class="preview-data font-weight-bold mr-3">{{actionTargetFields.find(item=> item.value === action.target_field)?.text || ''}}</span>
                        <span class="preview-data font-weight-bold mr-3">{{action.target_value}} <span class="font-weight-bold">KRW</span></span>
                      </template>
                    </v-col>

                  </v-row>
                </div>
              </v-form>
            </v-container>
            <v-container v-else class="rule-form-container px-5" style="background: white" fluid>
              <v-form ref="form">
                <v-row dense style="margin-bottom: 8px">
                  <v-col cols="12">
                    <CustomButton
                        @click="changeBidPrice"
                        style="margin-right: 10px"
                    >
                      입찰가 변경
                    </CustomButton>
                  </v-col>
                </v-row>
                <v-divider style="margin-bottom: 22px"/>
                <v-row dense>
                  <v-col cols="6">
                    <div class="custom-form-control">
                      <span class="custom-form-control--title">
                        규칙명
                      </span>
                      <text-input
                          v-model="rule.name"
                          placeholder="규칙명"
                          :rules="[(v)=> !!v || 'Name is required']"
                      >
                      </text-input>
                    </div>
                  </v-col>

                </v-row>
                <!--                <v-row dense>-->
                <!--                  <v-col cols="12">-->
                <!--                    <v-checkbox color="#e33223" class="custom-checkbox" readonly v-model="activated" label="Activated"></v-checkbox>-->
                <!--                  </v-col>-->
                <!--                </v-row>-->
                <v-row dense>
                  <v-col cols="3">
                    <div class="custom-form-control">
                      <span class="custom-form-control--title">
                        설정 항목
                      </span>
                      <Dropdown
                          v-model="rule.data_type"
                          :items="ruleTypes"
                          placeholder="Data type"
                          selected-text-bold
                          :rules="[(v)=> !!v || 'Data type is required']"
                      >
                      </Dropdown>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="custom-form-control">
                    <span class="custom-form-control--title">
                      규칙 종류
                    </span>
                      <dropdown
                          v-model="rule.rule_type"
                          :items="modes"
                          placeholder="Rule type"
                          selected-text-bold
                          :disabled="isDisabledRuleType"
                      >
                      </dropdown>
                    </div>

                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="4">
                    <div class="custom-form-control">
                <span class="custom-form-control--title">
                  우선순위
                </span>
                      <v-col>
                        <v-row align="center" style="flex-wrap: nowrap">
                          <text-input
                              v-model="rule.priority"
                              placeholder="Priority"
                              type="number"
                              :rules="[(v)=>parseInt(v) >= 1 && parseInt(v) <= 10 || '작은 숫자일수록 우선순위가 높습니다. ( 1 ~ 10 )']"
                          >
                          </text-input>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-hover v-slot="{ hover }">
                                <v-btn
                                    color="#e33223"
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    icon
                                    class="ml-1"
                                    style="margin-bottom: 18px"
                                >
                                  <v-icon :color="getHoverColor(hover)">mdi-information-variant-circle</v-icon>
                                </v-btn>
                              </v-hover>
                            </template>
                            <span>작은 숫자일수록 우선순위가 높습니다. ( 1 ~ 10 )</span>
                          </v-tooltip>

                        </v-row>
                      </v-col>
                    </div>
                  </v-col>

                </v-row>
                <template v-if="rule.rule_type === RuleType.Automation">
                  <v-row align="center" dense>
                    <v-col cols="5" style="max-width: 270px">
                      <div class="custom-form-control">
                        <span class="custom-form-control--title">시작 시간: </span>
                        <TimePicker
                            v-model="rule.turn_on_time"
                            outlined
                            dense
                            :rules="[
                                () => !(!!rule.turn_off_time &&  !!rule.turn_on_time===false) || '시작 시간을 설정해주세요.',
                                () => !(!!rule.turn_on_time &&rule.turn_on_time === rule.turn_off_time) || '시작 시간과 종료 시간은 같을 수 없습니다.'
                                ]"
                        ></TimePicker>

                      </div>
                    </v-col>
                    <v-col cols="5" style="max-width: 270px">
                      <div class="custom-form-control">
                        <span class="custom-form-control--title">종료 시간: </span>
                        <TimePicker
                            v-model="rule.turn_off_time"
                            outlined
                            dense
                            :rules="[() => !(!!rule.turn_on_time &&  !!rule.turn_off_time===false) || '종료 시간을 설정해주세요.']"
                        ></TimePicker>
                      </div>
                    </v-col>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                              color="#e33223"
                              v-bind="attrs"
                              v-on="on"
                              small
                              icon
                              style="margin-bottom: 18px; margin-top: 26px"
                          >
                            <v-icon :color="getHoverColor(hover)">mdi-information-variant-circle</v-icon>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>규칙 동작 시간은 모두 비어 있을 경우, 24시간 동작합니다.</span>
                    </v-tooltip>
                  </v-row>
                </template>
                <template v-if="rule.rule_type === RuleType.Scheduler">
                  <v-row dense align="center">
                    <v-col cols="4">
                      <div class="custom-form-control">
                        <span class="custom-form-control--title">실행시간</span>
                        <TimePicker
                            v-model="schedulerRuntime"
                            label="실행시간"
                            outlined
                            :rules="[(v)=> !!v || '실행 시간을 입력해주세요.']"
                            dense
                        />
                      </div>

                    </v-col>
                  </v-row>
                </template>
                <div
                    v-for="(set,setIndex) in rule.condition_action_sets"
                    :key="'set-'+setIndex"
                    style="position: relative"
                    class="set-condition-container"
                >
                  <div  class="py-3">
                    <v-divider></v-divider>

                  </div>
                  <v-btn
                      v-if="rule.condition_action_sets.length > 1"
                      small
                      icon
                      style="position: absolute ; right: 16px; top: 20px"
                      @click="()=>rule.condition_action_sets.splice(setIndex, 1)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-row dense class="pt-2 pb-1">
                    <v-col>
                      조건:
                    </v-col>
                  </v-row>
                  <!--                  <v-row v-if="set.conditions.length === 0 && rule.rule_type === RuleType.Automation">-->
                  <!--                    <v-col>-->
                  <!--                      <span class="error&#45;&#45;text">Conditions can not be empty with type Automation </span>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <v-row align="center" dense v-for="(con, conIndex) in set.conditions" :key="'con-'+conIndex">
                    <v-col cols="3">
                      <dropdown
                          v-model="con.field"
                          :items="conditionFields"
                          :loading="fieldsLoading"
                          placeholder="Field"
                          selected-text-bold
                          :rules="[(v)=> !!v || 'Field is required']"
                      >
                      </dropdown>

                    </v-col>
                    <dropdown
                        style="max-width: 160px; margin-left: 4px;"
                        v-model="con.operator"
                        :items="conditionOperators"
                        placeholder="Operator"
                        selected-text-bold
                        :rules="[(v)=> !!v || 'Field is required']"
                    >
                    </dropdown>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                              color="#e33223"
                              v-bind="attrs"
                              v-on="on"
                              small
                              icon
                              style="margin-bottom: 18px;"
                          >
                            <v-icon :color="getHoverColor(hover)">mdi-information-variant-circle</v-icon>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>200% / 50% 조건의 경우 기존 조건에 추가로 실행되는 조건입니다.</span>
                    </v-tooltip>

                    <v-col cols="3">
                      <text-input
                          v-model="con.value"
                          placeholder="Value"
                          type="number"
                          solo
                          dense
                          class="font-weight-bold"
                          :rules="[(v)=> !!v || 'Value is required']"
                      >
                      </text-input>
                    </v-col>
                    <span
                        v-if="rule.rule_type === RuleType.Automation && set.conditions.length === 1"
                        class="mx-3"
                        style="height: 24px; width: 24px "
                    ></span>
                    <v-btn
                        v-else
                        icon
                        class="mx-3"
                        @click="deleteCondition(setIndex, conIndex)"
                        style="margin-bottom: 18px"
                    >
                      <v-icon color="#e33223">mdi-delete</v-icon>
                    </v-btn>
                    <v-col cols="1">
                      <custom-button
                          v-if="conIndex === set.conditions.length - 1"
                          small
                          @click="addCondition(setIndex)"
                          style="
                            margin-bottom: 18px;
                            height: 35px;
                          "
                          height="35px"
                          :disabled="isDisabledRuleType"
                      >
                        <!--                        <v-icon color="#e33223">-->
                        <img style="margin-right: 10px" src="@/assets/plus.svg">
                        <!--                        </v-icon>-->
                        조건 추가
                      </custom-button>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="set.conditions.length === 0">
                    <v-col cols="12">
                      <CustomButton
                          style="width: 100%"
                          @click="addCondition(setIndex)"
                          :disabled="isDisabledRuleType"
                      >조건 추가
                      </CustomButton>
                    </v-col>

                  </v-row>
                  <v-row dense>
                    <v-col cols="3">
                      액션(실행):
                    </v-col>
                    <v-col cols="9">
                      <span>*필수: 설정하고자 하는 광고그룹 입찰전략에 따른 자동/수동 설정에 따른 규칙 설정 필요</span>
                    </v-col>
                  </v-row>
                  <v-row align="center" dense v-for="(action, actionIndex) in set.actions" :key="'action-'+actionIndex">
                    <v-col cols="3">
                      <dropdown
                          v-model="action.action_type"
                          :items="actionTypes"
                          placeholder="Action type"
                          selected-text-bold
                          :rules="[(v)=> !!v || 'Action type is required']"
                      >
                      </dropdown>
                    </v-col>
                    <template v-if="action.action_type===ActionType.TurnOff">
                      <v-col cols="2" style="margin-bottom: 18px;" align="center">
                        <span style="margin-bottom: 18px">다시 켜지는 시간 :</span>
                      </v-col>

                      <v-col cols="auto">
                        <v-row align="center" style="flex-wrap: nowrap">
                          <TimePicker
                              v-model="action.turn_on_time"
                          />
                          <v-tooltip top>


                            <template v-slot:activator="{ on, attrs }">
                              <v-hover v-slot="{ hover }">
                                <v-btn
                                    color="#e33223"
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    icon
                                    style="margin-bottom: 18px"
                                >
                                  <v-icon :color="getHoverColor(hover)">mdi-information-variant-circle</v-icon>
                                </v-btn>
                              </v-hover>
                            </template>
                            <span>켜기 시간은 비어 있을 수 있습니다.</span>
                          </v-tooltip>
                        </v-row>
                        <!--                        <div style="display: flex; flex-wrap: nowrap;align-items: center">-->
                        <!--                         -->
                        <!--                        </div>-->


                      </v-col>

                    </template>
                    <template v-else>
                      <v-col cols="4">
                        <dropdown
                            v-model="action.target_field"
                            :items="actionTargetFields"
                            placeholder="타겟 값"
                            :loading="fieldsLoading"
                            selected-text-bold
                            :rules="[(v)=> !!v || 'Field is required']"
                        >
                        </dropdown>

                      </v-col>
                      <v-col cols="4">
                        <text-input
                            v-model="action.target_value"
                            placeholder="Value"
                            type="number"
                            suffix="KRW"
                            class="font-weight-bold"
                            :rules="[(v)=> !!v || 'Value is required']"
                        >
                        </text-input>
                      </v-col>
                    </template>
                  </v-row>
                </div>

              </v-form>

            </v-container>

          </v-card-text>
          <v-card-actions class="px-5">
            <v-row dense class="mb-2" justify="end">
              <v-col v-if="error" class="error--text"  cols="12">
                {{error}}
              </v-col>
              <template v-if="isPreviewMode">
                <v-col class="text-center mb-1" cols="12">
                  규칙이 생성되었습니다.
                </v-col>
                <v-col  cols="12">
                  <CustomButton
                      width="100%"
                      :loading="loading"
                      :disabled="loading"
                      @click="closeDialog"
                      style="
                      box-shadow:  0px 0px 4px 0px rgba(227,50,35,1);
                      background-color: rgb(254, 245, 244);
                      color: #e33223;
                    "
                  >
                    확인
                  </CustomButton>
                </v-col>
              </template>

              <template v-if="!isPreviewMode">
                <v-col  cols="12" class="mb-4">
                  <CustomButton
                      width="100%"
                      :loading="loading"
                      :disabled="loading"
                      @click="addGroup"
                      class="mr-2"
                      style="color: #e33223;"
                  >
                    <img src="@/assets/plus.svg" style="margin-right: 10px" alt="">
                    조건-규칙 그룹 추가
                  </CustomButton>
                </v-col>
                <v-col  cols="12">
                  <CustomButton
                      width="100%"
                      :loading="loading"
                      :disabled="loading"
                      @click="handleSaveCondition(rule)"
                      style="
                      box-shadow:  0px 0px 4px 0px rgba(227,50,35,1);
                      background-color: rgb(254, 245, 244);
                      color: #e33223;
                    "
                  >
                    {{ actionName }}
                  </CustomButton>
                </v-col>
              </template>

            </v-row>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {HttpClient} from "@/plugins/http-client";
import {DateUtils} from "@/utils/DateUtils";
import {DataType, Rule, RuleType} from "@/views/Rule";
import {Condition, ConditionOperator} from "@/views/Condition";
import {Action, ActionType} from "@/views/Action";
import TimePicker from "@/components/TimePicker.vue";
import TextInput from "@/components/TextInput.vue";
import Dropdown from "@/components/Dropdown.vue";
import CustomButton from "@/components/CustomButton.vue";
import {PostEvent, PostEventData} from "@/constants/PostEvent";

@Component({
  components: {CustomButton, TextInput, TimePicker, Dropdown}
})
export default class RuleView extends Vue {
  RuleType = RuleType;
  ActionType = ActionType;
  PostEvent = PostEvent;
  loading = true;
  fieldsLoading = true;
  visible = false
  isCreate = true;
  isPreviewMode= false;
  rule = Rule.empty()
  conditionFields: {text: string, value: string}[] = []
  actionTargetFields: {text: string, value: string}[]= [];
  error=''
  activated = true

  getHoverColor(hover: boolean) {
    return hover ? '#e33223' : '#505050'
  }

  get actionName() {
    return this.isCreate ? '생성하기' : '수정하기'
  }

  get title() {
    return this.isCreate ? '규칙 생성' : '규칙 수정'
  }

  get isTest(){
    return this.$route.query?.test === 'true'
  }

  get testId():string| undefined{
    return this.$route.query?.id as any
  }

  get ruleTypes() {
    return [
      {text: '계정', value: DataType.Account},
      {text: '캠페인', value: DataType.Campaign},
      {text: '그룹', value: DataType.AdSet},
      {text: '소재', value: DataType.Creative}
    ]
  }

  get isDisabledRuleType() {
    return this.rule.data_type !== DataType.AdSet
  }

  get actionTypes() {
    const types = [
      {text: "증가", value: ActionType.Increase},
      {text: "감소", value: ActionType.Decrease},
      // {text: "Double Increase", value: ActionType.DoubleIncrease},
      // {text: "Double Decrease", value: ActionType.DoubleDecrease},
      {text: "설정", value: ActionType.Set},
      // {text: "Turn Off Next Time", value: ActionType.TurnOffNextTime},
      {text: "끄기", value: ActionType.TurnOff},
    ]
    return this.rule.data_type === DataType.AdSet ? types : [{text: "끄기", value: ActionType.TurnOff}]
  }

  getDisplayTime(value: string | null) {
    if(value){
      const [hours, minutes, seconds] = value.split(':');
      if(+hours >= 12){
        const stringHours = (+hours - 12) >= 10 ? (+hours - 12).toString() : `0${+hours - 12}`
        return `${stringHours}:${minutes}:${seconds} PM`;
      }else{
        return `${hours}:${minutes}:${seconds} AM`;
      }
    } else {
      return '--';
    }
  }

  get modes() {
    return [
      {text: '시작~종료 시간설정', value: RuleType.Automation},
      {text: '실행 시간설정', value: RuleType.Scheduler}
    ]
  }

  get conditionOperators() {
    return [
      {text: '= 일치', value: ConditionOperator.Equal},
      {text: '≠ 불일치', value: ConditionOperator.NotEqual},
      {text: '> 초과', value: ConditionOperator.GreaterThan},
      {text: '< 미만', value: ConditionOperator.LessThan},
      {text: '≥ 이상', value: ConditionOperator.GreaterThanOrEqual},
      {text: '≤ 이하', value: ConditionOperator.LessThanOrEqual},
      {text: '> 200% 초과', value: ConditionOperator.GreaterThan200},
      {text: '< 50% 미만', value: ConditionOperator.LessThan50},
      {text: '≥ 200% 이상', value: ConditionOperator.GreaterThanOrEqual200},
      {text: '≤ 50% 이하', value: ConditionOperator.LessThanEqual50},

      // {text: '~', value: ConditionOperator.PassTime},
      // {text: '> ROAS', value: ConditionOperator.ROASGreaterThan},
      // {text: '< ROAS', value: ConditionOperator.ROASLessThan},
    ]
  }

  get schedulerRuntime(): string | null {
    if (this.rule.hour && this.rule.minute && this.rule.second) {
      return `${this.rule.hour}:${this.rule.minute}:${this.rule.second}`
    } else {
      return null
    }
  }

  set schedulerRuntime(value: string | null) {
    try {
      if (value) {
        const [hour, minute, second] = value.split(':')
        this.rule.hour = hour
        this.rule.minute = minute
        this.rule.second = second
      }

    } catch (e) {
      console.log("RuleView::setSchedulerRuntime::error::", e)
    }
  }

  formatDate(date: string) {
    return DateUtils.format(date)
  }

  async toggleWithId() {
    this.visible = true
    if(this.testId){
      this.isCreate = false;
      await this.handleLoadRule(+this.testId)
    }
    else {
      this.isCreate = true;
      this.rule = Rule.empty();
    }
  }

  async showDialog() {
    this.visible = true;
  }

  closeDialog() {
    this.visible = false;
  }

  @Watch('visible')
  onChangeVisible() {
    if (!this.visible) {
      this.postMessage({eventType: PostEvent.closeDialog})
    }
  }

  @Watch('rule.data_type')
  async onDataTypeChanged(type: DataType){
    if(type !== DataType.AdSet){
      if(this.rule.condition_action_sets.length >0){
        this.rule.condition_action_sets[0].conditions = [];
        if(this.rule.condition_action_sets[0].actions.length > 0){
          //set to turn off action
          this.rule.condition_action_sets[0].actions[0].action_type = ActionType.TurnOff;
        }
      }
      this.rule.rule_type = RuleType.Scheduler;
    }
    await this.handleLoadConditionFields()
  }

  async mounted() {
    this.toggleWithId()
    // this.handleLoadRule(this.testId)
    window.addEventListener('message', this.handleCatchMessage);
    try {
      this.loading = true;
       await this.handleLoadConditionFields()
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }

  }
   beforeDestroy() {
     window.removeEventListener('message', this.handleCatchMessage);
   }

  async handleCatchMessage(event: MessageEvent) {
    // console.log("RuleView::handleCatchMessage::showDialog::origin::", event)
    if(event.data?.eventType ==='show-rule-dialog'){
          if(event.data?.id){
            this.isCreate = false;
            this.showDialog()
            await this.handleLoadRule(event.data.id)

            // console.log("RuleView::handleCatchMessage::showDialog", event.data.id)
          }
          else {
            this.isCreate = true;
            this.showDialog()
            this.rule = Rule.empty();
              console.log("RuleView::handleCatchMessage::showDialog", event.data.id)
          }
    }
  }

  // async search() {
  //   try {
  //     this.loading = true
  //     const response: any = await HttpClient.instance.get(`/rule/rules/?page=1&page_size=40`)
  //     this.searchResult = response;
  //     console.log("Rule::", response)
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     this.loading = false
  //   }
  // }

  clickRow(item: any) {
    this.$router.push({name: 'RuleDetail', params: {id: item.id}});
  }

  addGroup() {
    this.rule.condition_action_sets = this.rule.condition_action_sets.concat([
      {
        conditions: [],
        actions: [Action.empty()]
      }
    ])
  }

  deleteCondition(setIndex: number, conIndex: number) {
    this.rule.condition_action_sets[setIndex].conditions.splice(conIndex, 1)
  }

  addCondition(setIndex: number) {
    this.rule.condition_action_sets[setIndex].conditions.push(Condition.empty())
  }

  validate() {
    return (this.$refs.form as any).validate()
  }

  async handleLoadConditionFields(){
    try{
      this.fieldsLoading =true
      const response: any = await HttpClient.instance.get(`/rule/rules/get_fields/`, {params: {data_type: this.rule.data_type}})
      console.log("RuleView::handleLoadConditionFields::", response)
      this.conditionFields = response?.conditions?.map((con:{value: string, name: string})=>{
        return  {text: con.name, value: con.value}
      }) || []
      this.actionTargetFields = response?.actions?.map((act:{value: string, name: string})=>{
        return  {text: act.name, value: act.value}
      }) || []
    } catch (e: any) {
      console.error("RuleView::handleLoadConditionFields::error::", e);
      this.error = e.message
    } finally {
      this.fieldsLoading = false
    }
  }

  async handleLoadRule(id: number) {
    try {
      this.loading = true
      this.error = ''
      console.log("RuleView::handleLoadRule::", this.rule)
      this.rule = await HttpClient.instance.get(`/rule/rules/${id}/`)

    } catch (e:any) {
      console.error(e);
      this.error = e.message
    } finally {
      this.loading = false
    }
  }

  async handleSaveCondition(rule: Rule) {
    try {
      if (this.validate()) {
        this.error = ''
        this.loading = true;
        if(this.isCreate){
          await this.createRule(rule)
          this.postMessage({eventType: PostEvent.RuleCreated})
        }
        else {
          await this.updateRule(rule)
          this.postMessage({eventType: PostEvent.RuleUpdated})
        }
        this.isPreviewMode = true;
      }
    } catch (e: any) {
      this.error = e?.message;

    } finally {
      this.loading = false
    }
  }

  async createRule(rule: Rule){
    await HttpClient.instance.post(`/rule/rules/`, rule)
  }

  async updateRule(rule: Rule){
    await HttpClient.instance.put(`/rule/rules/${rule.id}/`, rule)
  }

  postMessage(data:PostEventData) {
    window.parent.postMessage(data, '*')
    console.log("RuleView::postMessage::", data)
  }

  changeBidPrice(){
    this.rule.data_type = DataType.AdSet;
    this.rule.rule_type = RuleType.Automation;
    this.rule.condition_action_sets.map(set => {
      set.actions.map(action => {
        action.action_type = ActionType.Increase;
        action.target_field = 'bidPrice';
      })

    })
  }

}
</script>

<style lang="scss">
.preview-view{
  .row{
    margin-bottom: 18px;
  }
}
.rule-form-container{

  .row + .row--dense {
   margin-top: 4px;
  }
  .custom-form-control{
    display: flex;
    flex-direction: column;
    .custom-form-control--title{
      margin-bottom: 4px;
      font-size: 12px;
    }
    .preview-data {
      font-size: 16px;
    }
  }
  .col {
    padding-right: 6px;
  }

  .set-condition-container{
    &:hover{
      //background: rgb(254, 245, 244);
    }
  }
  .custom-checkbox{
    margin-top: 0;
    label{
      font-size: 12px;
    }
    .v-input__slot{
      margin-bottom: 4px;
    }
  }
}
</style>
