import Progress from "@/components/Progress.vue";


export class ProgressBar {
    private static instance?: Progress;

    static init(instance?: Progress ) {
        ProgressBar.instance = instance;
        console.log('ProgressBar::',  ProgressBar.instance);
    }

    static show( ){
            (ProgressBar.instance as any)?.show();
    }

    static hide() {
        (ProgressBar.instance as any)?.hide();
    }

}
