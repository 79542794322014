<template>
  <v-select
      class="custom-dropdown"
      :class="{'selected-text-bold': selectedTextBold}"
      :item-text="itemText"
      :item-value="itemValue"
      :rules="rules || []"
      :hint="hint"
      v-bind="$attrs"
      v-on="$listeners"
      flat
      solo
      dense
      height="35px"
  />
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    'item-text': {
      type: String,
      default: 'text',
    },
    'item-value': {
      type: String,
      default: 'value',
    },
    rules: {
      type: Array,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    selectedTextBold: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
.custom-dropdown{
  .v-input__slot {
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    min-height: 35px !important;
    i{
      color: #e33223 !important;
    }
    margin-bottom: 4px;
  }
  .v-text-field__details{
    margin-bottom: 0 !important;
  }
}

.selected-text-bold {
  .v-input__control {


    .v-select__selection {
      font-weight: bold;
    }

  }


}
</style>
