<template>
  <v-menu
      class="custom-time-picker"
      ref="menu"
      v-model="menu"
      bottom
      :close-on-content-click="false"
      :nudge-right="33"
      transition="scale-transition"
      offset-y
      :max-width="`${menuWidth}px`"
      :min-width="`${menuWidth}px`"
      :max-height="`${menuHeight}px`"
      content-class="time-picker-custom-content-class"
  >
    <template v-slot:activator="{ on, attrs }">
      <text-input
          ref="input"
          :value="displayTime"
          placeholder="시간 선택"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          clearable
          solo
          oulined
          v-bind="attrs"
          :rules="rules"
          v-on="on"
          @click="()=>menu = !menu"
          @click:clear="()=> syncedValue = null"
          dense
      ></text-input>
    </template>
      <v-row v-if="menu" class="h-100" dense :style="{width: `${menuWidth}px`}">
        <v-col ref="hourList" cols="3" class="overflow-y-auto white-bg" :style="{height: `${menuHeight}px`}">
            <v-list dense >
              <v-list-item-group
                  color="#e33223"
                  :value="hours"
                  :mandatory="mandatory"
                  v-scroll
              >
                <v-list-item
                    v-for="(item, i) in 12"
                    :key="i"
                    :value="i >=10 ?`${i}` : `0${i}`"
                    @click="()=>hours = i >=10 ? `${i}` : `0${i}`"
                    :class="`time-picker-hour-${i >=10 ?`${i}` : `0${i}`}`"
                >
                  {{i >=10 ? i : `0${i}`}}
                </v-list-item>
              </v-list-item-group>
            </v-list>
        </v-col>
        <v-col cols="3" class="overflow-y-auto" :style="{height: `${menuHeight}px`}">
          <v-list dense >
            <v-list-item-group
                color="#e33223"
                :value="minutes"
                :mandatory="mandatory"
            >
              <v-list-item
                  v-for="(item, i) in 60"
                  :key="i"
                  :value="i >=10 ? `${i}`: `0${i}`"
                  @click="()=>minutes = i >=10 ? `${i}` : `0${i}`"
                  :class="`time-picker-minute-${i >=10 ?`${i}` : `0${i}`}`"
              >
                {{i >=10 ? i : `0${i}`}}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="3" class="overflow-y-auto" :style="{height: `${menuHeight}px`}">
          <v-list dense >
            <v-list-item-group
                color="#e33223"
                :value="seconds"
                :mandatory="mandatory"
            >
              <v-list-item
                  v-for="(item, i) in 60"
                  :key="i"
                  :value="i >=10 ? `${i}` : `0${i}`"
                  @click="()=>seconds = i >=10 ? `${i}` : `0${i}`"
                  :class="`time-picker-second-${i >=10 ?`${i}` : `0${i}`}`"
              >
                {{i >=10 ? i : `0${i}`}}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="3" class="overflow-y-auto" :style="{height: `${menuHeight}px`}">
          <v-list dense >
            <v-list-item-group
                color="#e33223"
                v-model="timePeriod"
            >
              <v-list-item
                  value="AM"
              >
                AM
              </v-list-item>
              <v-list-item
                  value="PM"
              >
                PM
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

      </v-row>
<!--    <v-time-picker-->
<!--        v-if="menu"-->
<!--        v-model="syncedValue"-->
<!--        full-width-->
<!--        @change="(time)=>timeChange(time)"-->
<!--        use-seconds-->
<!--        color="#e33223"-->
<!--    ></v-time-picker>-->
  </v-menu>
</template>
<script lang="ts">
import {Component, Model, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import TextInput from "@/components/TextInput.vue";
import {TimeoutUtils} from "@/utils/TimeoutUtils";

@Component({
  components: {TextInput}
})
export default class TimePicker extends Vue{
  menu = false;
  mandatory = false;

  @Model('input', {type: String})
  value!: string;

  @Prop({required: false, default: ()=> []})
  rules!: any[];

  @Prop({required: false, default: ()=> 200})
  menuHeight!: number

  get menuWidth(){
    //@ts-ignore
    return this.$refs?.input?.$el.clientWidth  || 200
  }


  get syncedValue(){
      return this.value
  }

  set syncedValue(value: string|null){
      if(value){
        this.mandatory = true
      } else {
        this.mandatory = false;

      }
      this.$emit('input', value);
  }

  @Watch('menu')
  async onMenuChange(value: boolean){
    await TimeoutUtils.sleep(150)
    if(this.$refs.hourList && value && this.value){
      console.log("TimePicker::onMenuChange::this.$refs.hourList::", this.$refs.hourList)
      //@ts-ignore
      this.$refs.hourList?.$el?.querySelector(`.time-picker-hour-${this.hours}`)?.scrollIntoView()
      //@ts-ignore
      this.$refs.hourList?.$el?.querySelector(`.time-picker-minute-${this.minutes}`)?.scrollIntoView()
      //@ts-ignore
      this.$refs.hourList?.$el?.querySelector(`.time-picker-second-${this.seconds}`)?.scrollIntoView()
    }
  }

  get hours(): string| null{
    try {
      if(this.value){
        const hours = this.value.split(':')[0];
        if(+hours >= 12){
          return (+hours - 12 ) >= 10 ? (+hours - 12 ).toString() : `0${+hours - 12}`
        } else {
          return hours;
        }
      }   else {
        return null;
      }
    } catch (e){
      return null;
    }

  }
  set hours(value: string|null){
    try {
      console.log("TimePicker::hours::value::", value)
      let [_, minutes, seconds] = ['00', '00', '00'];
      if(this.value){
        [_, minutes, seconds] = this.value.split(':');
      }
        if(this.timePeriod === 'PM'){
          const hours =  (+(value||0) + 12).toString();
          this.syncedValue = `${hours}:${minutes}:${seconds}`;
        } else  {
          this.syncedValue = `${value}:${minutes}:${seconds}`;
        }
    } catch (e) {
      //
    }
  }

  get minutes(){
    if(this.value){
      return this.value.split(':')[1];
    } else {
      return '';
    }
  }
  set minutes(value: string){
    try {
      console.log("TimePicker::minutes::value::", value)
      let [hours, minutes, seconds] = ['00', '00', '00'];
      if(this.value){
        [hours, minutes, seconds] = this.value.split(':');
      }
      this.syncedValue = `${hours}:${value}:${seconds}`;
    } catch (e) {
      //
    }
  }

  get seconds(){
    if(this.value){
      return this.value.split(':')[2];
    } else {
      return '';
    }
  }
  set seconds(value: string){
    try {
      console.log("TimePicker::second::value::", value)
      let [hours, minutes, seconds] = ['00', '00', '00'];
      if(this.value){
        [hours, minutes, seconds] = this.value.split(':');
      }
      this.syncedValue = `${hours}:${minutes}:${value}`;
    } catch (e) {
      //
    }
  }

  get timePeriod(){
    try {
      if(this.value){
        const [time, period] = this.displayTime.split(' ');
        return period;
      } else {
        return '';
      }
    } catch (e){
      return '';
    }
  }
  set timePeriod(value: string){
    try {
      let [hours, minutes, seconds] = ['00', '00', '00'];
      if(this.value){
        const [time, period] = this.value.split(' ');
        [hours, minutes, seconds] = this.value.split(':');
        if(+hours >= 12 && value === 'AM'){
          const stringHours = (+hours - 12) >= 10 ? (+hours - 12).toString() : `0${+hours - 12}`
          this.syncedValue = `${stringHours}:${minutes}:${seconds}`;
        } else if(+hours < 12 && value === 'PM'){
          this.syncedValue = `${+hours+12}:${minutes}:${seconds}`;
        }
      }
    } catch (e){
      //
    }
  }

  get displayTime(){
    if(this.value){
      const [hours, minutes, seconds] = this.value.split(':');
      if(+hours >= 12){
        const stringHours = (+hours - 12) >= 10 ? (+hours - 12).toString() : `0${+hours - 12}`
        return `${stringHours}:${minutes}:${seconds} PM`;
      }else{
        return `${hours}:${minutes}:${seconds} AM`;
      }
    } else {
      return '';
    }
  }

  mounted(){
    console.log('TimePicker::mounted::',this.$refs.input)
  }

  timeChange(time: string){
    try {
      //@ts-ignore
      this.$refs.menu?.save(time);
      console.log("TimePicker::timeChange::value::", time);
    } catch (e) {
      //
    }
  }

  // minuteChanged(value: string){
  //   try {
  //     const [hours, minutes, seconds] = this.value.split(':');
  //     this.value = `${hours}:${value}:${seconds}`;
  //     //@ts-ignore
  //
  //     this.$refs.menu?.save(this.value);
  //   } catch (e) {
  //     //
  //   }
  // }
  //
  // hourChanged(value: string){
  //   try {
  //     const [hours, minutes, seconds] = this.value.split(':');
  //     this.value = `${value}:${minutes}:${seconds}`;
  //     //@ts-ignore
  //     this.$refs.menu?.save(this.value);
  //   } catch (e) {
  //     //
  //   }
  // }
}
</script>

<style lang="scss">

.time-picker-custom-content-class{
  height: 200px;
  background: white;
  .v-list-item{
    padding: 0;
    justify-content: center;

  }
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    cursor: pointer;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #505050;
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    //background: #b30000;
  }
}
.custom-time-picker{
  .v-time-picker-clock__container{
    width: 100px;
    height: 100px;
  }
}
</style>
