

export enum ConditionOperator {
    Equal = 'eq',
    NotEqual = 'neq',
    GreaterThan = 'gt',
    LessThan = 'lt',
    GreaterThanOrEqual = 'gte',
    LessThanOrEqual = 'lte',
    GreaterThan200 = 'gt_200',
    GreaterThanOrEqual200 = 'gte_200',
    LessThan50 = 'lt_50',
    LessThanEqual50 = 'lte_50',
    PassTime= 'time',
    ROASGreaterThan = 'roas_gt',
    ROASLessThan = 'roas_lt'
}

export class Condition {
    constructor(
        public field: string,
        public operator: string,
        public value: string,
        public turn_on_time: string|null
    ) {}

    static empty(): Condition {
        return new Condition(
            '',
            ConditionOperator.GreaterThan,
            '0',
            null
        );
    }
}
