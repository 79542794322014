<template>
  <v-text-field
      class="custom-text-input"
      color="#cdcdcd"
      :active="false"
      dense
      solo
      flat
      :rules="rules || []"
      v-bind="$attrs"
      v-on="$listeners"
      height="35px"
  ></v-text-field>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    rules: {
      type: Array,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.custom-text-input {
  .v-input__control{
    min-height: 35px !important;
    .v-input__slot {
      border: 1px solid #cdcdcd;
      border-radius: 10px;
      margin-bottom: 4px;
    }
    .v-text-field__details{
      margin-bottom: 0;
      font-weight: normal;
    }
  }

}


</style>
