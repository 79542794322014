import {Condition} from "@/views/Condition";
import {Action} from "@/views/Action";

export enum RuleType{
    Automation = 'automation',
    Scheduler = 'scheduler'
}

export enum DataType{
    Account = 'Account',
    Campaign = 'Campaign',
    AdSet = 'AdSet',
    Creative = 'Creative'
}


export class Rule {
    constructor(
        public id: number,
        public name: string,
        public priority: number,
        public data_type: DataType,
        public rule_type: RuleType,
        public turn_on_time: string|null,
        public turn_off_time: string|null,
        public hour: string|null,
        public minute: string|null,
        public second: string|null,
        public condition_action_sets: {conditions: Condition[], actions: Action[]}[]
    ) {}


    static empty(): Rule {
        return new Rule(
            -1,
            '',
            1,
            DataType.Account,
            RuleType.Scheduler,
            null,
            null,
            "00",
            "00",
            "00",
            [
                {
                    conditions: [],
                    actions: [Action.empty()]
                }
            ]
        );
    }


}
