<template>
  <v-dialog v-model="visible" persistent width="500">
    <v-card color="primary" dark>
      <v-card-text>
        Processing...
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">

import {Component, Vue} from "vue-property-decorator";

@Component
export default class Progress extends Vue {
  private visible = false;

  public show(){
    this.visible = true;
  }

  public hide(){
    this.visible = false;
  }
}
</script>

<style lang="scss" scoped></style>
